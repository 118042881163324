<template>
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">
				<img id="logo" src="/admin/layout/images/gazdasagi.png" alt="Gazdasági Hivatal" style="height: 3.5rem" />
			</router-link>
			<!--
			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a>
			-->
			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
			<div class="layout-topbar-actions-left">
				<MegaMenu :model="items" class="layout-megamenu"></MegaMenu>
            </div>

			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">
					<li class="layout-topbar-item">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
							<img id="avatar" src="/backend/images/person/thumb/avatar.png" alt="avatar" style="width: 32px; height: 32px;" />
						</a>

						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<span>Név: <b>{{ displayname }}</b></span>
									</a>
								</li>								
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-cog" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Beállítások</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-file-o" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Használati leírás</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple>
										<i class="pi pi-compass" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Támogatás</span>
									</a>
								</li>
								<li class="layout-topbar-action-item">
									<a class="flex flex-row align-items-center p-ripple" v-ripple @click.prevent="logOut">
										<i class="pi pi-power-off" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Kijelentkezés</span>
									</a>
								</li>
							</ul>
						</transition>
					</li>

				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthImageService from "./service/AuthImageService";
	import AuthService from './service/AuthService';

	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
		data() {
			return {
				searchText: '',
				itemsaccess: [
					{
						label: 'Autó nyilvántartás',
						icon: 'fa-solid fa-car', 
						to: '/auto',
						access: 'auto',
					},
					{
						label: 'Tábori Támogatások',
						icon: 'fa-solid fa-mountain-sun', 
						// to: '/taboritamogatasok',
						access: 'frontendtaboritamogatasok',
						items: [
							[
                                /*
								{
									label: 'Tábori támogatások - 2023',
									items: [	
                                        {
                                            label: 'Tábori támogatás elszámoló letöltése',
                                            icon: 'fa-solid fa-download',
                                            to: '/taborielszamolasletoltese',
                                            access: 'frontendtaboritamogatasok',
                                        },
                                        {
                                            label: 'Tábori támogatás elszámoló beküldése',
                                            icon: 'fa-solid fa-money-bill-wheat',
                                            to: '/taborielszamolasfeltoltese',
                                            access: 'frontendtaboritamogatasok',
                                        },
                                        {
                                            label: 'Tábori támogatás beszámoló beküldése',
                                            icon: 'fa-solid fa-solid fa-envelopes-bulk',
                                            to: '/taboribeszamolofeltoltese',
                                            access: 'frontendtaboritamogatasok',
                                        },
                                        {
                                            label: 'Tábori támogatás számlák feltöltése',
                                            icon: 'fa-solid fa-file-invoice-dollar',
                                            to: '/taboriszamlafeltoltese',
                                            access: 'frontendtaboritamogatasok',
                                        },
                                        {
                                            label: 'Tábori támogatás összefoglaló',
                                            icon: 'fa-solid fa-mountain-sun',
                                            to: '/taboririport',
                                            access: 'frontendtaboritamogatasok',
                                        },
                                    ],
								},
                                 */
                                {
                                    label: 'Tábori támogatások - 2024',
                                    items: [
                                        {
                                            label: 'Tábori támogatás elszámoló letöltése',
                                            icon: 'fa-solid fa-download',
                                            to: '/taborielszamolasletoltese2024',
                                            access: 'frontendtaboritamogatasok2024',
                                        },
                                        {
                                            label: 'Tábori támogatás elszámoló beküldése',
                                            icon: 'fa-solid fa-money-bill-wheat',
                                            to: '/taborielszamolasfeltoltese2024',
                                            access: 'frontendtaboritamogatasok2024',
                                        },
                                        {
                                            label: 'Tábori támogatás beszámoló beküldése',
                                            icon: 'fa-solid fa-solid fa-envelopes-bulk',
                                            to: '/taboribeszamolofeltoltese2024',
                                            access: 'frontendtaboritamogatasok2024',
                                        },
                                        {
                                            label: 'Tábori támogatás számlák feltöltése',
                                            icon: 'fa-solid fa-file-invoice-dollar',
                                            to: '/taboriszamlafeltoltese2024',
                                            access: 'frontendtaboritamogatasok2024',
                                        },
                                        {
                                            label: 'Tábori támogatás összefoglaló',
                                            icon: 'fa-solid fa-mountain-sun',
                                            to: '/taboririport2024',
                                            access: 'frontendtaboritamogatasok2024',
                                        },
                                    ],
                                },

							],
						]
					},

                    {
                        label: 'Stóladíj',
                        icon: 'fa-solid fa-chart-simple',
                        access: 'frontendstola',
                        items: [
                            [
                                {
                                    label: 'Stóladíj - 2024',
                                    items: [
                                        {
                                            label: 'Stóladíj - 2024 - táblázat letöltése',
                                            icon: 'fa-solid fa-download',
                                            to: '/stola2024letoltese',
                                            access: 'frontendstola',
                                        },
                                        {
                                            label: 'Stóladíj - 2024 - táblázat és dokumentum feltölése',
                                            icon: 'fa-solid fa-upload',
                                            to: '/stola2024feltoltese',
                                            access: 'frontendstola',
                                        },
                                    ],
                                },
                            ],
                        ]
                    },




				],				
				items: [],
				roles: [],
				avatar: 'avatar.png',
				displayname: '',					
			}
		},
		created() {
			// console.log ("START CREATED", this.itemsaccess)
			this.items = this.itemsaccess.filter(this.getIsAccess);
			// console.log ("END CREATED", this.items)
		},
		mounted() {
			this.avatar = JSON.parse(localStorage.getItem('user'))?.avatar
			this.displayname = JSON.parse(localStorage.getItem('user'))?.name;
			if (this.displayname == undefined) { this.displayname = 'Ismeretlen'; }
			AuthImageService.displayProtectedImage("avatar", "/backend/auth/images/person/thumb/"+this.avatar)
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean
		},
		methods: {
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			/*
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			*/
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				}

				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			},
			logOut() {
				this.$store.dispatch('auth/logout');
				this.$router.push('/logout');
			},
			getIsAccess(access) {
				// console.log ('menu'+access.access, 'access', access, AuthService.getIsAccess('menu'+access.access))
				return AuthService.getIsAccess('menu'+access.access)
			},				
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>

<style>
div.p-menuitem-content span.p-menuitem-text {
	color: #FFFFFF!important;
}
div.p-menuitem-content svg.p-menuitem-icon {
	color: #FFFFFF!important;
}

div.p-megamenu-panel div.p-menuitem-content span.p-menuitem-text {
	color: #000!important;
}
div.p-megamenu-panel div.p-menuitem-content svg.p-menuitem-icon {
	color: #000!important;
}

li.p-megamenu-submenu-header.p-submenu-header {
	color: #558B2F!important;
	font-weight: bold;
}


</style>
