import { createRouter, createWebHistory } from 'vue-router';

const routes = [{
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () =>
            import ('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Főoldal' }],
        },
    },
    {
        path: '/auto',
        name: 'auto',
        exact: true,
        component: () =>
            import ('./pages/Auto.vue'),
        meta: {
            breadcrumb: [{ label: 'Autó' }],
        },
    },
    {
        path: '/taboritamogatasok',
        name: 'taboritamogatasok',
        exact: true,
        component: () =>
            import ('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taborielszamolasletoltese',
        name: 'taborielszamolasletoltese',
        exact: true,
        component: () =>
            import ('./pages/Taborielszamolasletoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taborielszamolasfeltoltese',
        name: 'taborielszamolasfeltoltese',
        exact: true,
        component: () =>
            import ('./pages/Taborielszamolasfeltoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboribeszamolofeltoltese',
        name: 'taboribeszamolofeltoltese',
        exact: true,
        component: () =>
            import ('./pages/Taboribeszamolofeltoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboriszamlafeltoltese',
        name: 'taboriszamlafeltoltese',
        exact: true,
        component: () =>
            import ('./pages/Taboriszamlafeltoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboririport',
        name: 'taboririport',
        exact: true,
        component: () =>
            import ('./pages/Taboririport.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },

    {
        path: '/taboritamogatasok2024',
        name: 'taboritamogatasok2024',
        exact: true,
        component: () =>
            import ('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taborielszamolasletoltese2024',
        name: 'taborielszamolasletoltese2024',
        exact: true,
        component: () =>
            import ('./pages/Taborielszamolasletoltese2024.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taborielszamolasfeltoltese2024',
        name: 'taborielszamolasfeltoltese2024',
        exact: true,
        component: () =>
            import ('./pages/Taborielszamolasfeltoltese2024.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboribeszamolofeltoltese2024',
        name: 'taboribeszamolofeltoltese2024',
        exact: true,
        component: () =>
            import ('./pages/Taboribeszamolofeltoltese2024.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboriszamlafeltoltese2024',
        name: 'taboriszamlafeltoltese2024',
        exact: true,
        component: () =>
            import ('./pages/Taboriszamlafeltoltese2024.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },
    {
        path: '/taboririport2024',
        name: 'taboririport2024',
        exact: true,
        component: () =>
            import ('./pages/Taboririport2024.vue'),
        meta: {
            breadcrumb: [{ label: 'Tábori támogatások' }],
        },
    },

    {
        path: '/stola2023letoltese',
        name: 'stola2023download',
        exact: true,
        component: () =>
            import ('./pages/Stola2023letoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Stóladíj táblázat letöltés - 2023' }],
        },
    },
    {
        path: '/stola2023feltoltese',
        name: 'stola2023upload',
        exact: true,
        component: () =>
            import ('./pages/Stola2023feltoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Stóladíj táblázat feltöltés - 2023' }],
        },
    },

    {
        path: '/stola2024letoltese',
        name: 'stola2024download',
        exact: true,
        component: () =>
            import ('./pages/Stola2024letoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Stóladíj táblázat letöltés - 2024' }],
        },
    },
    {
        path: '/stola2024feltoltese',
        name: 'stola2024upload',
        exact: true,
        component: () =>
            import ('./pages/Stola2024feltoltese.vue'),
        meta: {
            breadcrumb: [{ label: 'Stóladíj táblázat feltöltés - 2024' }],
        },
    },


    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('./pages/Login.vue')
    }, {
        path: '/logout',
        name: 'logout',
        component: () =>
            import ('./pages/Logout.vue')
    },

    {
        path: '/error',
        name: 'error',
        component: () =>
            import ('./pages/Error.vue')
    }, {
        path: '/notfound',
        name: 'notfound',
        component: () =>
            import ('./pages/NotFound.vue')
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/logout'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router;